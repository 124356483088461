<template>
    <div>
        <v-btn
            @click="action == save()"
            class="buttons buttons--add"
            :disabled="disabled"
            data-cy="save"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions([]),
        async save() {
            this.disabled = true
            this.$emit('save')
            this.disabled = false
        },
    },
}
</script>
