var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"details__table",attrs:{"headers":_vm.headers,"items":_vm.fields,"height":"calc(100vh -360px)","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(){return _vm._l((_vm.fields),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"table-row"},[_vm._v(_vm._s(_vm.translate(item)))]),_c('td',{staticClass:"table-row"},[_vm._v(" "+_vm._s(_vm.getGlobalAlertItem(item).active ? _vm.$t('global:yes') : _vm.$t('global:no'))+" ")]),_c('td',{staticClass:"table-row"},[_vm._v(" "+_vm._s(_vm.getGlobalAlertItem(item).message)+" ")]),_c('td',{staticClass:"table-row"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openEdit(item)}}},[_c('v-icon',{attrs:{"color":"secondary","small":""}},[_vm._v("mdi-pencil")])],1)],1)])})},proxy:true}])})],1)],1),_c('Modal',{attrs:{"title":_vm.$t('global:globalAlert'),"open":_vm.modalOpen},on:{"close":_vm.closeModal}},[_c('div',{attrs:{"slot":"AEContent"},slot:"AEContent"},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0"},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('global:globalAlert')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                                'alerts:addNewGlobalAlerts'
                                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('alerts:active')},model:{value:(
                                                            _vm.editingItem.active
                                                        ),callback:function ($$v) {_vm.$set(_vm.editingItem, "active", $$v)},expression:"\n                                                            editingItem.active\n                                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                                'alerts:contents'
                                                            )},model:{value:(
                                                            _vm.editingItem.message
                                                        ),callback:function ($$v) {_vm.$set(_vm.editingItem, "message", $$v)},expression:"\n                                                            editingItem.message\n                                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"border--green"},[_c('p',{staticClass:"ma-0 ml-5 primary--text"},[_vm._v(" "+_vm._s(_vm.$t( 'alerts:alertOptions' ))+" ")])])])],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"6"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"textGrey--text"},[_c('v-icon',[_vm._v("mdi-bell")]),_vm._v(" "+_vm._s(_vm.$t( 'alerts:notifyDay' )))],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                    on,
                                                                }){return [_c('v-icon',_vm._g({staticClass:"ml-3 cursor-pointer",attrs:{"size":"20"}},on),[_vm._v("mdi-information-variant")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( 'alerts:notifyInformation' )))])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('global:number')},model:{value:(
                                                            _vm.editingItem.timeAdvanceNumber
                                                        ),callback:function ($$v) {_vm.$set(_vm.editingItem, "timeAdvanceNumber", $$v)},expression:"\n                                                            editingItem.timeAdvanceNumber\n                                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)],1),_c('Buttons',{attrs:{"slot":"buttons"},on:{"save":_vm.save},slot:"buttons"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }