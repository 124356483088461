<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="py-0">
                <AdminHeader
                    :registryTitle="$t('global:registries')"
                    :registryUrl="'registries'"
                />
                <Main
                    :activeTab="activeTab"
                    :alertsTabs="false"
                    :tasksTabs="false"
                    :items="items"
                    ><v-tab-item
                        v-for="item in items"
                        :key="item.id"
                        slot="tabItems"
                        :id="item.id"
                    >
                        <component :is="item.component"></component>
                    </v-tab-item>
                    <div slot="information">
                        <Information />
                        <Modal
                            :title="$t('registries:edit')"
                            :open="open"
                            v-on:close="closeModal"
                        >
                            <AEContent slot="AEContent" />
                            <Buttons
                                @closeModal="open = false"
                                slot="buttons"
                                :action="'edit'"
                            />
                        </Modal>
                        <div class="d-flex justify-end buttons--details">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        fab
                                        dark
                                        v-on="on"
                                        small
                                        @click="
                                            open = true
                                            setRegistryModal()
                                        "
                                        class="buttons--add margin--1"
                                    >
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('registries:editRegistry') }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </Main>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Information from './../../../components/Admin/Registries/Details/Information'
import Buttons from './../../../components/Admin/Registries/Modal/Buttons'
import AEContent from './../../../components/Admin/Registries/Modal/Content'
import store from './../../../store/index'
import GlobalAlerts from './../../../components/Admin/Registries/Tabs/GlobalAlerts/Details/GlobalAlerts'
import { mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['registries', 'global'] },
    components: {
        Information,
        AEContent,
        Buttons,
        GlobalAlerts,
    },
    methods: {
        ...mapMutations([
            'clearRegistryModal',
            'setRegistryModal',
            'clearRegistryErrors',
        ]),
        closeModal() {
            this.clearRegistryErrors()
            this.open = false
        },
    },
    data() {
        return {
            open: false,
            activeTab: 'informations',
            items: [
                // {
                //     id: 'globalAlerts',
                //     icon: 'mdi-timetable',
                //     name: this.$t('global:globalAlert'),
                //     component: GlobalAlerts,
                // },
            ],
        }
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchRegistry', { id: to.params.id, next: next })
    },
}
</script>
