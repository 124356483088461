<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator
                            :text="$t('registries:specificInformation')"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="7">
                        <v-text-field
                            v-model="getRegistryDetails.name"
                            data-cy-details="registryName"
                            readonly
                            :label="$t('registries:registryName')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="5">
                        <v-text-field
                            readonly
                            data-cy-details="index"
                            v-model="getRegistryDetails.index"
                            :label="$t('registries:index')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="7">
                        <v-text-field
                            readonly
                            data-cy-details="url"
                            v-model="getRegistryDetails.url"
                            :label="$t('registries:url')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="5">
                        <v-text-field
                            readonly
                            data-cy-details="icon"
                            v-model="getRegistryDetails.icon"
                            :label="$t('registries:icon')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            readonly
                            data-registry-details="isActive"
                            :value="
                                getRegistryDetails.visible == false
                                    ? $t('registries:no')
                                    : $t('registries:yes')
                            "
                            :label="$t('registries:active')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            readonly
                            :value="folders"
                            :label="$t('registries:folders')"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" class="pt-5">
                        <Separator :text="$t('registries:registrySettings')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-1" md="6">
                        <v-text-field
                            readonly
                            data-cy-details="defaultStatus"
                            :value="
                                $get(
                                    getRegistryDetails,
                                    'defaultStatus.name',
                                    ''
                                )
                            "
                            :label="$t('registries:defaultStatus')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            readonly
                            :value="`${$get(
                                getRegistryDetails,
                                'currentMenager.name',
                                ''
                            )} ${$get(
                                getRegistryDetails,
                                'currentMenager.lastname',
                                ''
                            )}`"
                            :label="$t('registries:defaultMenager')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="6">
                        <v-textarea
                            :value="status"
                            no-resize
                            rows="1"
                            :label="$t('registries:possibleStatus')"
                            auto-grow
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                cols="6"
                xl="6"
                lg="8"
                md="12"
                sm="12"
                class="pa-5 details__card"
            >
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('registries:possibleMenager')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="6">
                        <div class="list-box">
                            <span class="list-box__label">{{
                                $t('registries:possibleMenager')
                            }}</span>
                            <div class="list-box__content">
                                <div
                                    v-for="(item,
                                    index) in getRegistryDetails.availableMenagers"
                                    :key="index"
                                    class="d-flex align-center mx-2 list-box__users"
                                >
                                    <v-avatar size="40" color="secondary">
                                        <img
                                            v-if="item.picture"
                                            :src="item.picture"
                                            alt="John"
                                        />
                                        <span class="white--text body-2" v-else
                                            >{{ item.name[0]
                                            }}{{ item.lastname[0] }}</span
                                        >
                                    </v-avatar>
                                    <div>
                                        <span class="mx-2 body-2">{{
                                            item.name
                                        }}</span>
                                        <span class="body-2">{{
                                            item.lastname
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['registries'] },
    data() {
        return {
            possibleStatus: [],
        }
    },
    computed: {
        ...mapGetters(['getRegistryDetails']),
        folders: {
            get() {
                const arr = []
                if (this.getRegistryDetails.availableFolders) {
                    this.getRegistryDetails.availableFolders.forEach(
                        element => {
                            if (element.isDeleted === false)
                                arr.push(` ${element.name}`)
                        }
                    )
                }
                return arr
            },
        },
        status: {
            get() {
                const arr = []
                const status = this.getRegistryDetails.availableStatuses.filter(
                    item => item.isDeleted == false
                )
                status.forEach(element => {
                    arr.push(` ${element.name}`)
                })
                return arr
            },
        },
    },
}
</script>
