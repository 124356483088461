var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('registries:specificInformation')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"data-cy-details":"registryName","readonly":"","label":_vm.$t('registries:registryName')},model:{value:(_vm.getRegistryDetails.name),callback:function ($$v) {_vm.$set(_vm.getRegistryDetails, "name", $$v)},expression:"getRegistryDetails.name"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"readonly":"","data-cy-details":"index","label":_vm.$t('registries:index')},model:{value:(_vm.getRegistryDetails.index),callback:function ($$v) {_vm.$set(_vm.getRegistryDetails, "index", $$v)},expression:"getRegistryDetails.index"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"readonly":"","data-cy-details":"url","label":_vm.$t('registries:url')},model:{value:(_vm.getRegistryDetails.url),callback:function ($$v) {_vm.$set(_vm.getRegistryDetails, "url", $$v)},expression:"getRegistryDetails.url"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"readonly":"","data-cy-details":"icon","label":_vm.$t('registries:icon')},model:{value:(_vm.getRegistryDetails.icon),callback:function ($$v) {_vm.$set(_vm.getRegistryDetails, "icon", $$v)},expression:"getRegistryDetails.icon"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"readonly":"","data-registry-details":"isActive","value":_vm.getRegistryDetails.visible == false
                                ? _vm.$t('registries:no')
                                : _vm.$t('registries:yes'),"label":_vm.$t('registries:active')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.folders,"label":_vm.$t('registries:folders')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('registries:registrySettings')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"readonly":"","data-cy-details":"defaultStatus","value":_vm.$get(
                                _vm.getRegistryDetails,
                                'defaultStatus.name',
                                ''
                            ),"label":_vm.$t('registries:defaultStatus')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$get(
                            _vm.getRegistryDetails,
                            'currentMenager.name',
                            ''
                        )} ${_vm.$get(
                            _vm.getRegistryDetails,
                            'currentMenager.lastname',
                            ''
                        )}`,"label":_vm.$t('registries:defaultMenager')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"value":_vm.status,"no-resize":"","rows":"1","label":_vm.$t('registries:possibleStatus'),"auto-grow":""}})],1)],1)],1),_c('v-col',{staticClass:"pa-5 details__card",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('registries:possibleMenager')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"list-box"},[_c('span',{staticClass:"list-box__label"},[_vm._v(_vm._s(_vm.$t('registries:possibleMenager')))]),_c('div',{staticClass:"list-box__content"},_vm._l((_vm.getRegistryDetails.availableMenagers),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center mx-2 list-box__users"},[_c('v-avatar',{attrs:{"size":"40","color":"secondary"}},[(item.picture)?_c('img',{attrs:{"src":item.picture,"alt":"John"}}):_c('span',{staticClass:"white--text body-2"},[_vm._v(_vm._s(item.name[0])+_vm._s(item.lastname[0]))])]),_c('div',[_c('span',{staticClass:"mx-2 body-2"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.lastname))])])],1)}),0)])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }