<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step :rules="[]" editable step="1">{{
                                $t('global:globalAlert')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="6">
                                        <v-checkbox
                                            v-model="editingItem.active"
                                            :label="$t('global:active')"
                                        ></v-checkbox>
                                    </v-col>

                                    <v-col cols="6"
                                        ><v-select
                                            v-model="
                                                editingItem.timeAdvanceUnit
                                            "
                                            :label="$t('global:unit')"
                                            :items="units"
                                        ></v-select
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="
                                                editingItem.timeAdvanceNumber
                                            "
                                            :label="$t('global:number')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="editingItem.message"
                                            :label="$t('global:message')"
                                        ></v-text-field> </v-col
                                ></v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        editingItem: {
            type: Object,
        },
    },
    data() {
        return {
            step: 1,
            editable: true,
        }
    },
    methods: {},

    computed: {},
}
</script>
