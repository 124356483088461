<template
    ><v-container>
        <v-row class="details__card">
            <!-- <v-list>
                <v-list-item-content v-for="item in fieldList" :key="item">
                    <v-list-item-title
                        >{{ item
                        }}<v-btn icon small @click="openEdit(item)"
                            ><v-icon>mdi-pen</v-icon></v-btn
                        ></v-list-item-title
                    >
                </v-list-item-content>
            </v-list> -->

            <!-- {{ items }} -->
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="fields"
                    height="calc(100vh -360px)"
                    fixed-header
                    class="details__table"
                    hide-default-footer
                >
                    <template v-slot:body>
                        <tr v-for="(item, index) in fields" :key="index">
                            <td class="table-row">{{ translate(item) }}</td>
                            <td class="table-row">
                                {{
                                    getGlobalAlertItem(item).active
                                        ? $t('global:yes')
                                        : $t('global:no')
                                }}
                            </td>
                            <td class="table-row">
                                {{ getGlobalAlertItem(item).message }}
                            </td>
                            <!-- <td class="table-row"></td> -->
                            <td class="table-row">
                                <v-btn icon small @click="openEdit(item)"
                                    ><v-icon color="secondary" small
                                        >mdi-pencil</v-icon
                                    ></v-btn
                                >
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <Modal
            :title="$t('global:globalAlert')"
            :open="modalOpen"
            @close="closeModal"
        >
            <!-- <AEContent :editingItem="editingItem" slot="AEContent" /> -->
            <div slot="AEContent">
                <v-container class="pt-0">
                    <v-row>
                        <v-col cols="12" class="pa-0">
                            <v-stepper class="elevation-0">
                                <template>
                                    <v-stepper-header
                                        class="elevation-0 stepperHeader"
                                    >
                                        <v-divider></v-divider>
                                        <v-stepper-step
                                            :rules="[]"
                                            editable
                                            step="1"
                                            >{{
                                                $t('global:globalAlert')
                                            }}</v-stepper-step
                                        >
                                        <v-divider></v-divider>
                                    </v-stepper-header>
                                    <v-stepper-items>
                                        <v-stepper-content step="1">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <Separator
                                                        :text="
                                                            $t(
                                                                'alerts:addNewGlobalAlerts'
                                                            )
                                                        "
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-checkbox
                                                        v-model="
                                                            editingItem.active
                                                        "
                                                        :label="
                                                            $t('alerts:active')
                                                        "
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="
                                                            editingItem.message
                                                        "
                                                        :label="
                                                            $t(
                                                                'alerts:contents'
                                                            )
                                                        "
                                                    ></v-text-field> </v-col
                                            ></v-row>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    md="12"
                                                    class="mt-5"
                                                    ><div class="border--green">
                                                        <p
                                                            class="ma-0 ml-5 primary--text"
                                                        >
                                                            {{
                                                                $t(
                                                                    'alerts:alertOptions'
                                                                )
                                                            }}
                                                        </p>
                                                    </div></v-col
                                                >
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" class="mt-5">
                                                    <v-col
                                                        class="pa-0"
                                                        cols="12"
                                                    >
                                                        <span
                                                            class="textGrey--text"
                                                        >
                                                            <v-icon
                                                                >mdi-bell</v-icon
                                                            >
                                                            {{
                                                                $t(
                                                                    'alerts:notifyDay'
                                                                )
                                                            }}</span
                                                        >

                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                }"
                                                            >
                                                                <v-icon
                                                                    size="20"
                                                                    class="ml-3 cursor-pointer"
                                                                    v-on="on"
                                                                    >mdi-information-variant</v-icon
                                                                >
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'alerts:notifyInformation'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-col>
                                                <!-- <v-col cols="6" class="mt-5">
                                                    <v-col
                                                        class="pa-0"
                                                        cols="12"
                                                        ><span
                                                            class="textGrey--text"
                                                        >
                                                            <v-icon
                                                                >mdi-calendar-refresh-outline</v-icon
                                                            >
                                                            {{
                                                                $t(
                                                                    'alerts:repeat'
                                                                )
                                                            }}</span
                                                        >
                                                    </v-col>
                                                </v-col> -->
                                            </v-row>

                                            <v-row>
                                                <v-col cols="2">
                                                    <v-text-field
                                                        v-model="
                                                            editingItem.timeAdvanceNumber
                                                        "
                                                        :label="
                                                            $t('global:number')
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <!-- <v-col cols="4"
                                                    ><v-select
                                                        v-model="
                                                            editingItem.timeAdvanceUnit
                                                        "
                                                        :label="
                                                            $t(
                                                                'alerts:periodOfTime'
                                                            )
                                                        "
                                                        :items="units"
                                                        item-value="value"
                                                        item-text="text"
                                                    ></v-select
                                                ></v-col> -->

                                                <!-- <v-col cols="2">
                                                    <v-text-field
                                                        v-model="
                                                            editingItem.repetitionNumber
                                                        "
                                                        :label="
                                                            $t('global:number')
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4"
                                                    ><v-select
                                                        v-model="
                                                            editingItem.repetitionTime
                                                        "
                                                        :label="
                                                            $t(
                                                                'alerts:periodOfTime'
                                                            )
                                                        "
                                                        :items="units"
                                                        item-value="value"
                                                        item-text="text"
                                                    ></v-select
                                                ></v-col> -->
                                            </v-row>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </template>
                            </v-stepper>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <Buttons @save="save" slot="buttons"></Buttons> </Modal
    ></v-container>
</template>

<script>
import i18next from 'i18next'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Buttons from './Buttons'
import AEContent from './Modal/Content'
export default {
    data() {
        return {
            fields: [],
            fieldList: [],
            modalOpen: false,
            units: [
                {
                    text: this.$t('alerts:hours'),
                    value: 'hours',
                },
                {
                    text: this.$t('alerts:minutes'),
                    value: 'minutes',
                },
                {
                    text: this.$t('alerts:days'),
                    value: 'days',
                },
            ],
            editingItem: {},
            headers: [
                {
                    text: this.$t('global:fieldAlertName'),
                    value: 'fieldAlertName',
                    width: '25%',
                },
                {
                    text: this.$t('global:activeAlert'),
                    value: 'activeAlert',
                    width: '15%',
                },
                {
                    text: this.$t('alerts:contents'),
                    value: 'comments',
                    width: '55%',
                },
                // {
                //     text: this.$t('global:lastModification'),
                //     value: 'lastModification',
                //     width: '40%',
                // },
                {
                    text: '',
                    value: 'actions',
                    width: '5%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Buttons,
        AEContent,
    },
    computed: {
        ...mapGetters(['getRegistryDetails', 'getProfileDetails']),
    },
    methods: {
        ...mapActions(['fetchRegistryDateFields', 'updateRegistry']),
        ...mapMutations(['setRegistryModal']),
        loadRegistryFields() {
            this.fieldMap = new Map()

            for (let field of this.fields) {
                let translated = this.translate(field)
                if (translated) {
                    this.fieldMap.set(translated, field)
                }
            }
            this.fieldList = [...this.fieldMap.keys()]
        },
        translate(value) {
            if (value.includes('.'))
                value = value.substring(
                    value.lastIndexOf('.') + 1,
                    value.length
                )
            return this.getKeyByValue(this.translations, value)
        },
        getKeyByValue(object, value) {
            for (let [prop, val] of Object.entries(object)) {
                if (value === prop) return val
            }
            return value
        },
        openEdit(item) {
            this.editingItem = this.getGlobalAlertItem(item)
            this.modalOpen = true
        },
        getGlobalAlertItem(val) {
            let item = this.getRegistryDetails.globalAlerts.find(
                e => e.field === val
            ) || { field: val }
            return item
        },
        closeModal() {
            this.modalOpen = false
        },

        save() {
            let alert = this.getRegistryDetails.globalAlerts.find(
                e => e.field === this.editingItem.field
            )
            if (alert) alert = this.editingItem
            else this.getRegistryDetails.globalAlerts.push(this.editingItem)
            this.setRegistryModal(this.getRegistryDetails)
            this.updateRegistry({ id: this.getRegistryDetails._id })
            this.closeModal()
        },
    },
    async beforeMount() {
        let lang = this.getProfileDetails.language || 'pl'
        this.translations = i18next.getResourceBundle(
            lang,
            this.getRegistryDetails.url
        )
        this.fields = await this.fetchRegistryDateFields({
            url: this.getRegistryDetails.url,
        })

        this.loadRegistryFields()
    },
}
</script>
<style lang="sass" scoped>
.table-row
    font-size: 14px
    padding: 0 15px
    height: 47px
    border-bottom: 1px solid #e0e0e0
</style>
